import './distributiva_hoteles.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { Freya } from '../src/components/Freya/Freya';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hela from '../src/components/Hela/Hela';
import Layout from '../src/components/Layout/Layout';
import { Odin } from '../src/components/Odin/Odin';
import Hod from '../src/components/Hod/Hod';
import SEO from '../src/helpers/seo';
import Thor from '../src/components/Thor/Thor';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import BannerImgTextButton from '../src/components/BannerImgTextButton/BannerImgTextButton';
import tt from '../src/helpers/translation';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query DistributivaHoteles($locale: String!) {
    allHotelsHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          name
          image
          localImage {
            childImageSharp {
              fluid(maxWidth: 1600, maxHeight: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allHotelMobileImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          name
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allHotelsSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allHotelsThorTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subtitle
        }
      }
    }
    allHotelsThorContent(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          Icon
          titleIcon
          subtitleIcon
          tag
        }
      }
    }
    allHotelsOdinData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          image
          alt
          title
          description
          buttons {
            cta1
            cta2
            ctaText1
            ctaText2
            size
            color1
            color2
          }
        }
      }
    }
    allHotelsBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          image1
          image2
          omit
          localImage1 {
            childImageSharp {
              fluid(maxWidth: 650, maxHeight: 360, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          localImage2 {
            childImageSharp {
              fluid(maxWidth: 650, maxHeight: 360, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }

          title
          title_path
          description
          cta_text
          cta_path
          price
          stars
        }
      }
    }
    allHotelsPromotedSubBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          cta
          ctaText
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allHotelBigParagraph(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          first_paragraph
          second_paragraph
          third_paragraph
          # localImage {
          #   childImageSharp {
          #     fluid(quality: 100) {
          #       ...GatsbyImageSharpFluid
          #     }
          #   }
          # }
          # image
        }
      }
    }
    distHotelsBreadCrumb(lang: { eq: $locale }) {
      destination_url
      name
    }
    allHotelsRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class DistributivaHoteles extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allHotelsHeaderBlock.edges[0].node.name,
      image: {
        alt: this.props.data.allHotelsHeaderBlock.edges[0].node.alt,
        url: this.props.data.allHotelsHeaderBlock.edges[0].node.localImage.childImageSharp.fluid,
      },
      imageMobile: {
        alt: this.props.data.allHotelMobileImage.edges[0].node.alt,
        url: this.props.data.allHotelMobileImage.edges[0].node.localImage.childImageSharp.fluid,
      },
    };

    const secondTitleData = {
      title: tt('ELIGE TU EXPERIENCIA', this.props.pageContext.locale),
      description: tt(
        'Descubre nuestros hoteles más increíbles y aprovecha al máximo tu visita.',
        this.props.pageContext.locale
      ),
    };

    const PrepareforHela = (src) => ({
      hotels: src.edges
        .map((e, i) => ({
          hotelCardData: {
            description: e.node.description,
            id: String(i),
            orangeButtonText: e.node.cta_text,
            orangeButtonURL: `${e.node.cta_path}?_ga=2.76409685.1700126798.1640597527-1648847946.1639043608`,
            stars: e.node.stars,
            title: e.node.title,
            omit: e.node.omit,
            whiteButtonURL: e.node.title_path,
          },
          images: [
            e.node.localImage1.childImageSharp.fluid,
            e.node.localImage2.childImageSharp.fluid,
          ],
          price: String(e.node.price),
        }))
        .filter((e) => e.hotelCardData.omit === false),
    });

    const PrepareforHelaMobile = (src) => ({
      hotels: src.edges
        .map((e, i) => {
          const link = e.node.cta_path;
          const ctaLink = link;
          return {
            hotelCardData: {
              description: e.node.description,
              id: String(i),
              orangeButtonText: e.node.cta_text,
              orangeButtonURL: `${ctaLink}?_ga=2.76409685.1700126798.1640597527-1648847946.1639043608`,
              stars: e.node.stars,
              title: e.node.title,
              omit: e.node.omit,
              whiteButtonURL: e.node.title_path,
            },
            images: [
              e.node.localImage1.childImageSharp.fluid,
              e.node.localImage2.childImageSharp.fluid,
            ],
            price: String(e.node.price),
          };
        })
        .filter((e) => e.hotelCardData.omit === false),
    });

    const hotelsData = {
      promotions: this.props.data.allHotelsPromotedSubBlock.edges,
    };

    const freyaData = {
      title: tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale),
      cards: hotelsData.promotions.map((hotels) => ({
        image: hotels.node.localImage.childImageSharp.fluid,
        altImage: hotels.node.image_alt,
        title: hotels.node.title,
        imgsize: 'promoted',
        buttons: {
          cta: hotels.node.cta,
          size: 'alone',
          color: 'white',
          ctaText: hotels.node.ctaText,
        },
      })),
    };

    const prepareForThor = () => {
      const ret = {
        title: this.props.data.allHotelsThorTitle.edges[0].node.title,
        subtitle: this.props.data.allHotelsThorTitle.edges[0].node.subtitle,
        cards: [],
      };
      this.props.data.allHotelsThorContent.edges.map((e) => {
        ret.cards.push({
          Icon: e.node.Icon,
          titleIcon: e.node.titleIcon,
          subtitleIcon: e.node.subtitleIcon,
        });
      });
      return ret;
    };

    const preparePromotion = (data) => {
      const obj = JSON.parse(JSON.stringify(data));
      const link = obj.buttons.cta2;
      obj.buttons.cta2 = `${link}?_ga=2.76409685.1700126798.1640597527-1648847946.1639043608`;
      return obj;
    };

    const isMobileHela = (
      <>
        <Odin
          data={
            this.props.data.allHotelsOdinData.edges[0].node &&
            preparePromotion(this.props.data.allHotelsOdinData.edges[0].node)
          }
          locale={this.props.pageContext.locale}
        />
        <Hela
          locale={this.props.pageContext.locale}
          data={PrepareforHelaMobile(this.props.data.allHotelsBlock)}
        />
      </>
    );

    const isDesktopHela = (
      <>
        <Odin
          data={this.props.data.allHotelsOdinData.edges[0].node}
          locale={this.props.pageContext.locale}
        />
        <Hela
          locale={this.props.pageContext.locale}
          data={PrepareforHela(this.props.data.allHotelsBlock)}
        />
      </>
    );

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <SEO
          title={this.props.data.allHotelsSeoData.edges[0].node._0.title}
          description={this.props.data.allHotelsSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allHotelsRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allHotelsHeaderBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="hotel-content">
              <div className="hotel-content-grey">
                <NeoAesir
                  locale={this.props.pageContext.locale}
                  pageName={this.props.pageContext.pageName}
                  title={this.props.data.distHotelsBreadCrumb.name}
                />
              </div>
              <div className="order-container_test-ab">
                <div className="t-page-block-1">
                  <Thor data={prepareForThor()} />
                </div>
                <div className="t-page-block-2">
                  <BlueArrow />
                  <BannerImgTextButton locale={this.props.pageContext.locale} />
                  <H3 data={secondTitleData} />
                  <MediaServerRender predicted="mobile" hydrated>
                    <MediaMatcher
                      mobile={isMobileHela}
                      tablet={isMobileHela}
                      desktop={isDesktopHela}
                    />
                  </MediaServerRender>
                </div>
              </div>
              <div className="BigParagraph-hotel">
                <div className="BigParagraph-hotel-image">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.data.allHotelBigParagraph.edges[0].node.first_paragraph,
                    }}
                  />
                  <img src={this.props.data.allHotelBigParagraph.edges[0].node.image} />
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.allHotelBigParagraph.edges[0].node.second_paragraph,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.allHotelBigParagraph.edges[0].node.third_paragraph,
                  }}
                />
              </div>
              <div className="related-promotions">
                <H4 title={tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale)} />
                <Freya data={freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
/* eslint-disable react/require-default-props */
DistributivaHoteles.propTypes = {
  ourHotelsData: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.object,
  }),
};
/* eslint-enable react/require-default-props */

export default DistributivaHoteles;
