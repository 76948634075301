import React from 'react';
import tt from '../../helpers/translation';
import Buttons from '../Buttons/Buttons';
import style from './BannerImgTextButton.module.scss';

const BannerImgTextButton = (locale) => {
  return (
    <div className={style.bannerGastro}>
      <img
        src={tt(
          'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/014/539/modal_width/img-banner-es.jpg',
          locale.locale
        )}
        alt="banner desktop"
        className={style.bannerImgDesktop}
      />
      <img
        src={tt(
          'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/014/539/modal_width/img-banner-es.jpg',
          locale.locale
        )}
        alt="banner mobile"
        className={style.bannerImgMobile}
      />
      <div className={style.bannerTextContainer}>
        <div className={style.bannerText}>
          <span>
            {tt(
              'Viaja a una experiencia gastronómica única que surge cuando tus cinco sentidos y los mundos de PortAventura World se fusionan en algo delicioso.',
              locale.locale
            )}
          </span>
          <br />
          <span>{tt('Bienvenidos a DELION.', locale.locale)}</span>
          <br />
          <span>{tt('Cuando lo delicioso se convierte en emoción.', locale.locale)}</span>
        </div>
        <Buttons
          named
          link="delion_experience"
          size="alone"
          color="orange"
          text={tt('DESCUBRE DELION', locale.locale)}
        />
      </div>
    </div>
  );
};

export default BannerImgTextButton;
